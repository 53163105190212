import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-unknown-place-table-header',
  templateUrl: './unknown-place-table-header.component.html',
  styleUrls: ['./unknown-place-table-header.component.scss']
})
export class UnknownPlaceTableHeaderComponent implements OnInit {
  @Output() filterValues = new EventEmitter();
  @Output() onSelectAll = new EventEmitter(false);
  @Input() allSelected: boolean;
  showCheckIcon = {};
  sortDirection: "ASC" | "DESC";
  sortParams = {};
  appliedFilters = {};
  maxDate = new Date();
  quantityCount = null;
  inventoryCount = null;
  form = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    lastMovedAt: new FormControl(null),
    sku: new FormControl(null),
    tagRfid: new FormControl(null)
  });

  constructor() { }

  ngOnInit(): void {
  }

  sort(orderBy: string, sortBy: "ASC" | "DESC") {
    this.sortDirection = sortBy;
    this.showCheckIcon[orderBy] = true;
    this.sortParams = { orderBy, sortBy };
  }

  clearFilters() {
    this.appliedFilters = {};
    this.showCheckIcon = {};
    this.sortDirection = null;
    this.sortParams = null;
    this.quantityCount = null;
    this.inventoryCount = null;
    this.form.reset();
    this.filterValues.emit();
  }

  applyFilters() {
    const startDate = this.form.value.startDate ? moment(this.form.value.startDate._d).valueOf() : null;
    const endDate = this.form.value.endDate ? moment(this.form.value.endDate._d).endOf('day').valueOf() : null;
        
    const params = {
      startDate: startDate,
      endDate: endDate,
      quantity: this.quantityCount,
      inventoryCount: this.inventoryCount,
      sku: this.form.value.sku,
      lastMovedAt: this.form.value.lastMovedAt,
      tagRfid: this.form.value.tagRfid
    };

    this.removeNullKeys(params);
    this.filterValues.emit({
      filterParams: params,
      sortParams: this.sortParams
    });
  }

  removeNullKeys(params) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      } else {
        this.setAppliedFilters(key);
      }
    });
  }

  changeQuantity(operator: "PLUS" | "MINUS") {
    if (operator === 'PLUS') {
      return this.quantityCount++;
    } else if (operator === 'MINUS' && this.quantityCount > 0) {
      return this.quantityCount--;
    }
  }

  changeInventoryCount(operator: "PLUS" | "MINUS") {
    if (operator === 'PLUS') {
      return this.inventoryCount++;
    } else if (operator === 'MINUS' && this.inventoryCount > 0) {
      return this.inventoryCount--;
    }
  }

  setAppliedFilters(name) {
    switch (name) {
      case 'startDate':
      case 'endDate':
        this.appliedFilters['createdAt'] = true;
        break;
      default:
        this.appliedFilters[name] = true;
        break;
    }
  }

  selectAll() {
    this.onSelectAll.emit(!this.allSelected);
  }

}
