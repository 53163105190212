import { Injectable } from '@angular/core';
import { PaginationService } from './pagination.service';
import { StockFilterParams } from './stock.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CratesDataSourceService } from '../data-sources/crates-data-source.service';


@Injectable({
  providedIn: 'root'
})
export class MissingCratesService {
  public missingPallets$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private dataSource: CratesDataSourceService,
    private paginationService: PaginationService
  ) { }

  getMissingPallets(): Observable<any[]> {
    return this.missingPallets$.asObservable();
  }

  async init(filter: StockFilterParams = null) {
    return this.getPage(1, filter);
  }

  async reload(filter: StockFilterParams = null, sortParams = null) {
    return await this.getPage(this.paginationService.currentPage, filter, sortParams);
  }

  private async getPage(page: number, filter: StockFilterParams = {}, sortParams = null) {
    this.paginationService.currentPage = page;

    const params = {
      limit: this.paginationService.pageSize,
      offset: (this.paginationService.currentPage - 1) * this.paginationService.pageSize
    };
    Object.assign(params, sortParams);
    Object.assign(params, filter);
    const response = await this.dataSource.getMissingCrates(params);
    this.paginationService.totalItems = response.count;
    this.paginationService.pageCount = Math.ceil(this.paginationService.totalItems / this.paginationService.pageSize);
    this.paginationService.$items.next(response.crates);
    this.paginationService.$pageCount.next(this.paginationService.pageCount);
    this.paginationService.$totalItems.next(this.paginationService.totalItems);
    this.paginationService.$currentPage.next(this.paginationService.currentPage);
    this.paginationService.$atLastPage.next(this.paginationService.pageCount === 0 || this.paginationService.currentPage === this.paginationService.pageCount);
    this.paginationService.$atFirstPage.next(this.paginationService.currentPage === 1);
  }

  async getNextPage(filter = null) {
    if (this.paginationService.currentPage < this.paginationService.pageCount) {
      return await this.getPage(this.paginationService.currentPage + 1, filter);
    } else {
      throw Error('???');
    }
  }

  async getPreviousPage(filter = null) {
    if (this.paginationService.currentPage > 1) {
      return await this.getPage(this.paginationService.currentPage - 1, filter);
    } else {
      throw Error('???');
    }
  }

}
