<div class="wrapper">
  <div class="upper-content">
    <div class="first-column">
      <app-info-oc-or-nfe label="Tag RFID" [value]="crate.tagRfid"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Entrada" [value]="formatDate(crate.createdAt)"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Última movimentação" [value]="formatDate(crate.lastMovedAt)"></app-info-oc-or-nfe>
    </div>
    <div class="second-column">
      <!-- <app-info-oc-or-nfe label="Enviado para inventário" [value]="crate.activeInventoryCount"></app-info-oc-or-nfe> -->
      <app-info-oc-or-nfe label="Total de inventários" [value]="crate.inventoryCount"></app-info-oc-or-nfe>
    </div>
  </div>
  <div class="lower-content">
    <app-material-generic-table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [columnsDefinition]="columnsDefinition">
    </app-material-generic-table>
  </div>
</div>
