import { Component, Input, OnInit } from '@angular/core';
import formatDate from '../../../helpers/format-date';
import { MissingCrate } from 'src/models/Crate';

@Component({
  selector: 'app-missing-pallet-details',
  templateUrl: './missing-pallet-details.component.html',
  styleUrls: ['./missing-pallet-details.component.scss']
})
export class MissingPalletDetailsComponent implements OnInit {
  @Input() crate: MissingCrate;
  displayedColumns = ['name', 'packSize', 'sku', 'quantity'];
  columnsDefinition = [
    { key: 'name', text: "Nome" },
    { key: "packSize", text: 'Tamanho do pacote' },
    { key: "sku", text: 'Código do produto' },
    { key: "quantity", text: 'Quantidade' }
  ];
  dataSource: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.dataSource = this.crate.skuGroups;
  }

  formatDate(date) {
    return formatDate(date);
  }


}
