import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IEventHistory } from '../../../models/History';

@Injectable({
  providedIn: 'root'
})
export class EventHistoryService {

  constructor(private http: HttpClient) { }

  getEventHistory(crateId: string): Promise<IEventHistory[]> {
    return this.http.get<IEventHistory[]>(environment.baseUrl + `/api/v1/admin-desktop/crates/${crateId}/event-history`).toPromise();
  }
}
