import {Component, Input, OnInit} from '@angular/core';
import {IEventHistory, MovementType} from '../../../../models/History';
import {toExcelFormat} from '../../../helpers/format-position';
import * as moment from 'moment/moment';
import centimetersToMeters from '../../../helpers/centimeters-to-meters';
import { getTaskTypeName, getMovementTypeName } from '../../../helpers/format-task-type';
import { EventHistoryService } from '../../../core/data-sources/event-history.service';
import { TaskType } from '../../../pages/tasks/tasks-table-item/tasks-table-item.component';

@Component({
  selector: 'app-movement-details',
  templateUrl: './movement-details.component.html',
  styleUrls: ['./movement-details.component.scss']
})

export class MovementDetailsComponent implements OnInit {

  @Input() crateId: string;
  @Input() title: string;
  centimetersToMeters = centimetersToMeters;
  displayedColumns = ['type', 'taskType', 'user', 'deviceName', 'coords', 'position', 'levelName', 'height', 'movedAt'];
  dataMovement: IEventHistory[] = [];

  constructor(private eventHistoryService: EventHistoryService) { }

  ngOnInit(): void {
    this.getMovementHistory();
  }

  async getMovementHistory(): Promise<void> {
    this.dataMovement = await this.eventHistoryService.getEventHistory(this.crateId);
  }

  formatPosition(x: number, y: number): string {
    return toExcelFormat(x, y);
  }

  translateMovementType(type: MovementType): string {
    return getMovementTypeName(type);
  }

  showUsername(user): string {
    if (!user) {
      return '---';
    }

    return user.firstName + ' ' + user.lastName;
  }

  timestampToDate(timestamp: number): string {
    return timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm:ss.SSS') : '---';
  }

  translateTaskType(taskType: TaskType) {
    return getTaskTypeName(taskType);
  }

}
