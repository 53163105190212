export interface IEventHistory {
  timestamp: number;
  updatedAt?: number;
  createdAt: number;
  id: string;
  tenantId: string;
  crateId: string;
  userId: string;
  type: MovementType;
  x: number;
  y: number;
  z: number;
  deviceName: string;
  groundPositionAlias: string;
  groundPositionGroupLevelName: string;
  taskType: string;
  user: unknown;
}

export enum MovementType {
  FOUND = "FOUND",
	FOUND_MISPLACED = "FOUND_MISPLACED",
	MISSING = "MISSING",
	LOAD = "LOAD",
	UNLOAD = "UNLOAD"
}
