import { UserModel } from 'src/app/core/data-sources/user-data-source.service';
import { InventoryTypesEnum } from '../app/core/services/action.service';
import { CrateStateEnum } from './Crate';

export interface IReport {
  id: string;
  admin: {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    type: string;
    currentDevice: string;
    tenantId: string;
    logConfig: string;
    createdAt: number;
    updatedAt: number;
    lastSeenAt: number;
  };
  total: {
    items: number;
    found: number;
    missing: number;
    relocate: number;
  };
  data: IReportDataOld | IReportData | IReportDataInventoryByCrate;
  state: InventoryReportStateEnum;
  approver: {
    id: string;
    name: string;
  };
  tenantId: string;
  createdAt: number;
  updatedAt: number;
  user?: UserModel;
}

// to handle old reports
export interface IReportDataOld { // TODO: remove after adjusting the registries on db
  FOUND: ICrateData[];
  MISSING: ICrateData[];
  FOUND_MISPLACED: ICrateData[];
  type: InventoryTypesEnum;
}

export interface IReportData {
  found: ICrateData[];
  missing: ICrateData[];
  foundMisplaced: ICrateData[];
  type: InventoryTypesEnum;
  target?: {
    tagsRfid?: string[],
    skus?: string[],
    groundPositionGroupsId?: string[],
  }
}

export interface IReportDataInventoryByCrate {
  crate: {
    createdAt: number;
    id: string;
    inconsistency: {
      state?: string;
      tagRfid?: string;
      groundPositionGroupId?: string;
      x?: number;
      y?: number;
      z?: number;
    } | null;
    position: string;
    state: CrateStateEnum,
      tagRfid: string;
    tenantId: string;
    type: string;
    updatedAt: number;
    x: number;
    y: number;
    z: number;
  };
  skuGroup: {
    extra: ISkuGroupsData[]
    found: ISkuGroupsData[]
    missing: ISkuGroupsData[]
  };
  type: InventoryTypesEnum;
}

export interface ISkuGroupsData {
  name: string;
  packSize: number;
  quantity: number;
  sku: string;
  inconsistency: {
    packSize?: number;
    quantity?: number;
  } | null;
}

export interface ICrateData {
  [key: string]: any;
  removePallet?: boolean;
  x: number;
  y: number;
  z: number;
  id: string;
  type: string;
  state: string;
  tagRfid: string;
  position: string;
  tenantId: string;
  createdAt: number;
  updatedAt: number;
  skuGroups: {
    crateId: string,
    createdAt: number
    name: string,
    packSize: number,
    quantity: number,
    sku: string,
    updatedAt: number
  }[];
  inconsistency: {
    state: string;
    tagRfid: string;
    groundPositionGroupId: string;
    x?: number;
    y?: number;
    z?: number;
  } | null;
}

export enum InventoryReportStateEnum {
  AWAITING_ADMIN_APPROVAL = 'AWAITING_ADMIN_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export function translateState(state: InventoryReportStateEnum) {
  switch (state) {
    case InventoryReportStateEnum.AWAITING_ADMIN_APPROVAL:
      return 'Aguardando aprovação';
    case InventoryReportStateEnum.APPROVED:
      return 'Aprovado';
    case InventoryReportStateEnum.REJECTED:
      return 'Rejeitado';
  }
}

export function translateType(type: InventoryTypesEnum) {
  switch (type) {
    case InventoryTypesEnum.INVENTORY_BY_ITEM_TYPE:
      return 'Inventário por tipo de vinho';
    case InventoryTypesEnum.INVENTORY_BY_ITEM_PACKAGE:
      return 'Inventário por tipo de garrafa';
    case InventoryTypesEnum.INVENTORY_BY_ITEM_SET:
      return 'Inventário por país';
    case InventoryTypesEnum.INVENTORY_BY_ITEM_SKU:
      return 'Inventário por produto';
    case InventoryTypesEnum.INVENTORY_BY_PRODUCT:
      return 'Inventário por produto';
    case InventoryTypesEnum.INVENTORY_BY_TAG_RFID:
      return 'Inventário por tag RFID';
    case InventoryTypesEnum.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID:
      return 'Inventário por pallet';
    case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP:
      return 'Inventário por setor';
    case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION:
      return 'Inventário por posição';
    case InventoryTypesEnum.INVENTORY_BY_MISSING_CRATE:
      return 'Inventário de perdidos';
    default:
      return '---';
  }
}
