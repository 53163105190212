<app-unknown-place-table-header
  (filterValues)="onFiltersApply($event)"
  (onSelectAll)="selectAll($event)"
  [allSelected]="isAllSelected"
  >
</app-unknown-place-table-header>
<app-table-items>
  <app-table-item *ngFor="let crate of crates; let odd = odd" [isOdd]="odd">
    <app-table-column name="checkbox-btn" size="smallest">
      <mat-checkbox content (change)="onCheckboxClick(crate)" [checked]="crate.selected"></mat-checkbox>
    </app-table-column>

    <app-table-column name="createdAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.createdAt)}}</span>
    </app-table-column>

    <app-table-column name="lastMovedAt" size="small" (click)="itemClicked(crate)">
      <span content>{{formatDate(crate.lastMovedAt)}}</span>
    </app-table-column>

    <app-table-column name="product-code" size="small" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.sku }}</span>
      </span>
    </app-table-column>

    <app-table-column name="tagRfid" size="small" (click)="itemClicked(crate)">
      <span content class="ellipsis" matTooltip="{{crate.tagRfid}}">{{crate.tagRfid}}</span>
    </app-table-column>

    <app-table-column name="description" size="medium" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.name }}</span>
      </span>
    </app-table-column>

    <!-- <app-table-column name="sent" size="small" (click)="itemClicked(crate)">
      <span content>{{crate.activeInventoryCount ? "Sim" : "Não"}}</span>
    </app-table-column> -->

    <app-table-column name="quantity" size="smallest" alignRight="true" (click)="itemClicked(crate)">
      <span content>
        <span *ngFor="let product of crate.skuGroups">{{ product.quantity }}</span>
      </span>
    </app-table-column>

    <app-table-column name="total" size="small" alignRight="true" (click)="itemClicked(crate)">
      <span content>{{crate.inventoryCount}}</span>
    </app-table-column>
  </app-table-item>
</app-table-items>