import { MissingCratesService } from '../../../core/services/missing-crates.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import formatDate from '../../../helpers/format-date';
import { MissingCrate } from 'src/models/Crate';

@Component({
  selector: 'app-unknown-place-table',
  templateUrl: './unknown-place-table.component.html',
  styleUrls: ['./unknown-place-table.component.scss']
})
export class UnknownPlaceTableComponent implements OnInit, OnChanges {
  @Input() crates: MissingCrate[];
  @Output() clicked = new EventEmitter();
  @Output() onFilterValuesChange = new EventEmitter();
  selectedPallets: MissingCrate[] = [];
  isAllSelected = false;
  appliedFilters = {};
  sortParams = {
    orderBy: 'lastMovedAt',
    sortBy: 'DESC'
  };

  constructor(
    private missingPalletsService: MissingCratesService
  ) { }

  ngOnInit(): void {
    this.missingPalletsService.getMissingPallets().subscribe(value => {
      this.selectedPallets = value;
      this.crates.forEach(crate => {
        crate.selected = false;
        if (this.selectedPallets.length) {
          this.selectedPallets.forEach(selectedPallet => {
            if (crate.id === selectedPallet.id) {
              crate.selected = true;
            }
          });
        }
      });
    });
  }

  ngOnChanges(): void {
    this.isAllSelected = this.crates.length ? this.crates.every(item => item.selected) : false;
    this.crates.forEach(crate => {
      this.selectedPallets.findIndex(selectedPallet => {
        if (!this.isAllSelected && selectedPallet.selected && selectedPallet.id === crate.id) {
          crate.selected = true;
        }
      });
    });
  }

  itemClicked(crate) {
    this.clicked.emit({ crate: crate });
  }

  formatDate(date) {
    return formatDate(date);
  }

  onCheckboxClick(crate) {
    const index = this.selectedPallets.findIndex((item) => item.id === crate.id);
    if (index === -1) {
      crate.selected = true;
      this.selectedPallets.push(crate);
      this.missingPalletsService.missingPallets$.next(this.selectedPallets);
    } else {
      crate.selected = false;
      this.selectedPallets.splice(index, 1);
      this.missingPalletsService.missingPallets$.next(this.selectedPallets);
    }
    this.isAllSelected = this.crates.length ? this.crates.every(item => item.selected) : false;
  }

  onFiltersApply(params: { sortParams, filterParams }) {
    this.appliedFilters = params?.filterParams;
    this.sortParams = this.setSortParams(params?.sortParams);
    this.onFilterValuesChange.emit(params);
  }

  setSortParams(sort) {
    if (!sort || !sort.sortBy) {
      return;
    }
    return sort;
  }

  selectAll(select: boolean) {
    const selected = this.crates.filter(crate => crate.selected = select);
    this.selectedPallets = selected;
    this.isAllSelected = selected.length ? selected.every(item => item.selected) : false;
    this.missingPalletsService.missingPallets$.next(this.selectedPallets);
  }

}
