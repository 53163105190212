import { Component, Input, OnInit } from '@angular/core';
import formatDate from '../../../helpers/format-date';
import {
  IReport,
  IReportData,
  IReportDataInventoryByCrate,
  IReportDataOld,
  translateType
} from '../../../../models/Report';
import { InventoryTypesEnum } from '../../../core/services/action.service';
import getName from 'src/app/helpers/format-name';

@Component({
  selector: 'app-reports-table-item',
  templateUrl: './reports-table-item.component.html',
  styleUrls: ['./reports-table-item.component.scss']
})
export class ReportsTableItemComponent implements OnInit {

  @Input() report: IReport;
  reportData;
  totalItems: number;
  qtyFound: number;
  qtyMissing: number;

  formatDate = formatDate;

  constructor() {
  }

  ngOnInit(): void {
    this.setReportType();
  }

  setReportType(): void {
    switch (this.report.data.type) { // TODO: refactor this component
      case InventoryTypesEnum.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID:
        this.reportData = this.report.data as IReportDataInventoryByCrate;
        this.totalItems = this.report.total?.items
          || this.reportData?.skuGroup?.found?.length + this.reportData?.skuGroup?.extra?.length + this.reportData?.skuGroup?.missing?.length
          || '---';
        this.qtyFound = this.reportData?.skuGroup?.found?.length + this.reportData?.skuGroup?.extra?.length || '---';
        this.qtyMissing = this.reportData?.skuGroup?.missing?.length || '---';
        break;
      case InventoryTypesEnum.INVENTORY_BY_TAG_RFID:
      case InventoryTypesEnum.INVENTORY_BY_ITEM_SKU:
      case InventoryTypesEnum.INVENTORY_BY_ITEM_SET:
      case InventoryTypesEnum.INVENTORY_BY_ITEM_PACKAGE:
      case InventoryTypesEnum.INVENTORY_BY_ITEM_TYPE:
      case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION_GROUP:
      case InventoryTypesEnum.INVENTORY_BY_GROUND_POSITION:
      case InventoryTypesEnum.INVENTORY_BY_MISSING_CRATE:
      default:
        this.reportData = this.report.data as IReportData | IReportDataOld;
        this.totalItems = this.report.total?.items
          || this.reportData?.found?.length + this.reportData?.foundMisplaced?.length + this.reportData?.missing?.length
          || this.reportData?.FOUND?.length + this.reportData?.FOUND_MISPLACED?.length + this.reportData?.MISSING?.length
          || '---';
        this.qtyFound = this.report.total?.found
          || this.reportData?.found?.length + this.reportData?.foundMisplaced?.length
          || this.reportData?.FOUND?.length + this.reportData?.FOUND_MISPLACED?.length
          || '---';
        this.qtyMissing =
          this.report.total?.missing || this.reportData?.missing?.length || this.reportData?.MISSING?.length || '---';
        break;
    }
  }

  translateTypeName(type: InventoryTypesEnum) {
    return translateType(type);
  }

  getUserName(user) {
    return getName(user);
  }
}
