<div class="page-header">
  <app-prev-next-button direction="left" label="estoque" (click)="navigateToStock()"></app-prev-next-button>
  <h1>Local Desconhecido</h1>
</div>
<div class="ctrl-bar">
  <div class="buttons">
    <div class="inventory">
      <button mat-raised-button class="secondary-2" [disabled]="!selectedPallets.length"
        (click)="openSidenav('MISSING_CRATE_INVENTORY')">
        Inventariar
      </button>
    </div>

    <div class="update-button">
      <p>Atualizado às {{ actualHour }}</p>
      <button mat-raised-button (click)="update()">
        <i class="uil uil-sync"></i>
        <span>Atualizar</span>
      </button>
    </div>
  </div>
</div>
<app-side-color-card color="primary-1">
  <div content class="card-content">
    <div class="card-header">
      <h2>Pallets em local desconhecido ({{ paginationService.totalItems$ | async }})</h2>
      <app-paginator
        [currentPage]="paginationService.currentPage$ | async"
        [pageCount]="paginationService.pageCount$ | async"
        [atFirstPage]="paginationService.atFirstPage$ | async"
        [atLastPage]="paginationService.atLastPage$ | async"
        (previousPage)="getPreviousPage($event)"
        (nextPage)="getNextPage($event)">
      </app-paginator>
    </div>
    <div class="table">
      <app-unknown-place-table
        [crates]="data"
        (clicked)="onRowClick($event)"
        (onFilterValuesChange)="onFiltersApply($event)">
      </app-unknown-place-table>
      <div *ngIf="isLoading" class="loader">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <div class="empty" *ngIf="data?.length === 0 && !isLoading">
        NÃO EXISTEM PALLETS EM LOCAL DESCONHECIDO.
      </div>
    </div>
  </div>
</app-side-color-card>

<app-full-page-loader *ngIf="isModalLoading"></app-full-page-loader>