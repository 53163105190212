<ng-container [formGroup]="form">
  <app-table-header-column name="checkbox-btn" size="smallest" matTooltip="Selecionar todos desta página"
    (click)="selectAll()">
    <span content>
      <mat-checkbox (click)="$event.preventDefault()" [checked]="allSelected"></mat-checkbox>
    </span>
  </app-table-header-column>

  <app-table-header-column name="createdAt" size="small">
    <span content>
      Entrada
      <app-filter-button [column]="'createdAt'" [menu]="createdAtMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #createdAtMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por data de entrada</h4>
      <button (click)="sort('createdAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('createdAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['createdAt'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Data inicial</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Data final</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [max]="maxDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="lastMovedAt" size="small">
    <span content>
      Últ movimentação
      <app-filter-button [column]="'lastMovedAt'" [menu]="lastMovedAtMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #lastMovedAtMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Ordernar por data da última movimentação</h4>
      <button (click)="sort('lastMovedAt', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('lastMovedAt', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['lastMovedAt'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="product-code" size="small">
    <span content>
      Código do produto
      <app-filter-button [column]="'sku'" [menu]="skuMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #skuMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por código do produto</h4>
      <button (click)="sort('sku', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('sku', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['sku'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Código do produto" formControlName="sku" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="tagRfid" size="small">
    <span content>
      Tag Rfid
      <app-filter-button [column]="'tagRfid'" [menu]="tagRfidMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #tagRfidMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por Tag Rfid</h4>
      <button (click)="sort('tagRfid', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['tagRfid'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('tagRfid', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['tagRfid'] && sortDirection == 'ASC'"></i>
      </button>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Tag Rfid" formControlName="tagRfid" autocomplete="off">
      </mat-form-field>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="description" size="medium">
    <span content>Descrição</span>
  </app-table-header-column>

  <!-- <app-table-header-column name="sent" size="small">
    <span content>Enviado para inventário</span>
  </app-table-header-column> -->

  <app-table-header-column name="quantity" size="smallest" alignRight="true">
    <span content>
      Qtd
      <app-filter-button [column]="'quantity'" [menu]="quantityFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #quantityFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por quantidade</h4>
      <button (click)="sort('quantity', 'DESC')" mat-menu-item>
        Ordem decrescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'DESC'"></i>
      </button>
      <button (click)="sort('quantity', 'ASC')" mat-menu-item>
        Ordem crescente
        <i class="uil uil-check" *ngIf="showCheckIcon['quantity'] && sortDirection == 'ASC'"></i>
      </button>
      <section class="quantity">
        <p>QUANTIDADE</p>
        <div class="counter">
          <div class="operator-button" (click)="changeQuantity('MINUS')">
            <i class="uil uil-minus"></i>
          </div>
          <span>{{ quantityCount }}</span>
          <div class="operator-button" (click)="changeQuantity('PLUS')">
            <i class="uil uil-plus"></i>
          </div>
        </div>
      </section>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

  <app-table-header-column name="inventoryCount" size="small" alignRight="true">
    <span content>
      Total de inventários
      <app-filter-button [column]="'inventoryCount'" [menu]="inventoryCountFilterMenu" [appliedFilters]="appliedFilters"></app-filter-button>
    </span>
  </app-table-header-column>

  <mat-menu #inventoryCountFilterMenu="matMenu">
    <div class="menu-container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <h4>Filtrar por total de inventários</h4>
      <section class="quantity">
        <p>TOTAL DE INVENTÁRIOS</p>
        <div class="counter">
          <div class="operator-button" (click)="changeInventoryCount('MINUS')">
            <i class="uil uil-minus"></i>
          </div>
          <span>{{ inventoryCount }}</span>
          <div class="operator-button" (click)="changeInventoryCount('PLUS')">
            <i class="uil uil-plus"></i>
          </div>
        </div>
      </section>
      <section class="actions">
        <button mat-stroked-button class="gray-1" (click)="clearFilters()">Limpar</button>
        <button mat-raised-button class="primary-1" (click)="applyFilters()">Salvar</button>
      </section>
    </div>
  </mat-menu>

</ng-container>