import { MovementType } from "../../models/History";
import { TaskType } from "../pages/tasks/tasks-table-item/tasks-table-item.component";

const taskTypeName = new Map<TaskType, string>([
  [TaskType.RECEIVING, "Recebimento"],
  [TaskType.DISPATCHING, "Expedição"],
  [TaskType.LOAD_DISPATCH, "Saída"],
  [TaskType.RELOCATE_STORAGE, "Movimentação"],
  [TaskType.RELOCATE_POSITION, "Movimentação"],
  [TaskType.RELOCATE_EXPEDITION, "Movimentação"],
  [TaskType.RELOCATE_DIVERGENT, "Movimentação"],
  [TaskType.RELOCATE_QUALITY, "Movimentação"],
  [TaskType.RELOCATE_DOCK, "Movimentação"],
  [TaskType.RELOCATE_FREE, "Movimentação Livre"],
  [TaskType.RELOCATE_FROM_PICKING, "Realocação para separação"],
  [TaskType.INVENTORY_BY_GROUND_POSITION_GROUP, "Inventário por setor"],
  [TaskType.INVENTORY_BY_PRODUCT, "Inventário por produto"],
  [TaskType.INVENTORY_BY_TAG_RFID, "Inventário por tag RFID"],
  [TaskType.INVENTORY_BY_GROUND_POSITION, "Inventário por posição"],
  [TaskType.PRODUCT_INVENTORY_BY_CRATE_TAG_RFID, "Inventário por pallet"],
  [TaskType.INVENTORY_BY_MISSING_CRATE, "Inventário por pallet perdido"],
  [TaskType.PRODUCT_PICKING, "Separação de produto"],
  [TaskType.PICKING, "Separação"],
  [TaskType.EDIT_PALLET, "Edição de Pallet"]
]);

const movementTypeName = new Map<MovementType, string>([
  [MovementType.FOUND, "Encontrado por inventário"],
  [MovementType.FOUND_MISPLACED, "Encontrado em lugar indevido"],
  [MovementType.MISSING, "Perdido por inventário"],
  [MovementType.LOAD, "Início de movimentação"],
  [MovementType.UNLOAD, "Fim de movimentação"]
]);

export function getTaskTypeName(taskType: TaskType): string {
  return taskTypeName.get(taskType) || "---";
}

export function getMovementTypeName(movementType: MovementType): string {
  return movementTypeName.get(movementType) || "---";
}