import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MissingCrate } from 'src/models/Crate';

interface MissingCratesAPIResponse {
  count: number;
  crates: MissingCrate[];
}

@Injectable({
  providedIn: 'root'
})
export class CratesDataSourceService {

  constructor(private http: HttpClient) { }

  getMissingCrates(params?) {
    return this.http.get<MissingCratesAPIResponse>(environment.baseUrl + '/api/v2/admin-desktop/crates/missing', { params: params }).toPromise<MissingCratesAPIResponse>();
  }
}
